// @import './themes/colors/colors.scss';


.header_title_wrapper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;


    .header_title {
        color: white;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        text-transform: capitalize;
        text-align: center;
    }

    .search-container {
        position: relative;
        text-align: center;
        padding: 0rem 3.75rem;
    }

}

.box-shadow-right {
    z-index: 99;
}