$white: #FFFFFF;
$secondary: #F7941D;
$darkSecondary: #D17E1B;
$secondary-light: #f5e6d4;
$gray: #757575;
$LightGray: #D9D9D9;
$GrayLight: #FAFAFA;
$BgListing: #F5F5F5;
$greyHeading: #7F7F7F;
$AlertBackground: #1C4DA1;
$DarkGray: #3E3E3E;
$DarkCharcoal: #333333;
$SecondaryColorInactive: #FBCE98;
$greyBar:#f4f6fa;
$LightGreen: #e5f6dd;
$Green: #52C41A;
$Red: #FF4D4F;
$LightRed: #ffe4e5;
$Blue: #1c4da1;
$BgGray: #F0F2F5;
$editing: #fefadc;
$scrollThumb:#c6c6c6;
$thumbBackground:#ededed;