@import '/src/themes/colors/colors.scss';
@import '/src/themes/breakpoints/breakpoints.scss';

.password-page {
    width: 30rem;
    height: 26.75rem;
}

.preference-page {
    width: 40rem;
}

.templates-page {
    width: 56.25rem !important;
    height: 35.50rem !important;
    padding: 3rem;

    @media (max-width: $screen-lg-min) {
        padding: 2rem;
        width: 90% !important;
    }

    .ant-card-body {
        padding: 0.5rem 0.75rem;
    }

    .template-card-shadow {
        width: 100%;
        border-radius: 0.5rem;
        margin-bottom: 0.5rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1)
    }

    .template-card-row {
        text-align: left;
    }

    .templates-card-button {
        padding-top: 4rem;
    }
}

.time-page {
    width: 56.25rem !important;

    .time-card {
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .ant-card-body {
            padding: 0.5rem 0.75rem;
        }

        .time-card-shadow {
            width: 100%;
            border-radius: 0.5rem;
            margin-bottom: 0.5rem;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        }

        .time-card-button {
            padding-top: 2rem;
        }
    }
}

.keywords-page {
    width: 56.25rem !important;
    height: 34.37rem !important;
    padding-top: 0.5rem;

    .keywords-card {
        border-radius: 0.5rem;
        padding: 3.12rem !important;
        height: 30rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        overflow-y: auto;

        @media (max-width: $screen-sm-min) {
            padding: 1rem !important;
        }

        .ant-card-body {
            padding: 0.7rem 0.75rem;
        }

        .keywords-card-shadow {
            width: 100%;
            border-radius: 0.5rem;
            margin-bottom: 0.5rem;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
        }

        .keywords-card-button {
            padding-top: 2rem;
        }
    }

    .modal-tag {
        border-radius: 0.6rem !important;
        padding: 0.2rem 0.5rem !important;
        margin: 0.2rem !important;

    }
}

.time-autoresponder-setting {
    width: 44.87rem !important;
    height: 30rem !important;

    .ant-modal-header {
        padding: 0.5rem 0.75rem 0rem .75rem;
    }

    .ant-modal-body {
        padding: 0rem .75rem;

        .custom-tag {
            padding: 0.5rem 1.25rem;
            background-color: $greyBar;
            border: none !important;
            border-radius: 0.3rem;
            font-size: 1rem;
            font-weight: 500;
            color: inherit;
            cursor: pointer;
        }

        .selected-tag {
            padding: 0.5rem 1.25rem;
            background-color: $secondary;
            color: $white !important;
            border: none !important;
            border-radius: 0.3rem;
            font-size: 1rem;
            font-weight: 500;
            color: inherit;
            cursor: pointer;
        }
    }

    .ant-modal-footer {
        padding: 0rem 0.75rem 1rem 0.75rem;
    }
}

.vacation-page {
    width: 56.25rem !important;

    .vacation-box {
        width: 56.25rem !important;
        height: 20rem !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 0.5rem;
        padding: 1rem 2rem;
        overflow-y: auto;

        .vacation-card {
            padding: 2rem 0rem;

            .ant-card-body {
                padding: 0.5rem 1rem;
            }

            .vacation-card-shadow {
                width: 100%;
                border-radius: 0.5rem;
                margin-bottom: 0.5rem;
                box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
            }

            .vacation-card-button {
                padding-top: 2rem;
            }
        }
    }
}

.time-edit-custom-setting {
    width: 41.875rem !important;
    height: 28rem !important;

    .ant-modal-header {
        padding: 0.5rem 0.75rem 0rem .75rem;
    }

    .ant-modal-body {
        padding: 0rem .75rem;
    }

    .ant-modal-footer {
        padding: 0rem 0.75rem 1rem 0.75rem;
    }
}

.time-custom-setting {
    width: 42rem !important;
    height: 28rem !important;

    .ant-modal-header {
        padding: 0.5rem 0.75rem 0rem .75rem;
    }

    .ant-modal-body {
        padding: 0rem .75rem;

        .tag {
            border-radius: 0.6rem !important;
            padding: 0rem 0.5rem !important;
            background-color: light beige !important;
        }

    }

    .ant-modal-footer {
        padding: 0rem 0.75rem 1rem 0.75rem;
    }
}

.keywrods-modal {
    height: 30.625rem;
    width: 27.5rem;

    .ant-modal-header {
        padding: 0.5rem 0.75rem 0rem .75rem;
    }

    .ant-modal-body {
        padding: 0rem .75rem;

        .tag {
            border-radius: 0.6rem !important;
            padding: 0rem 0.5rem !important;
            background-color: light beige !important;
        }
    }

    .ant-modal-footer {
        padding: 0rem 0.75rem 1rem 0.75rem;
    }
}

.template-Modal {
    height: 30.18rem !important;
    width: 45rem !important;

    .ant-modal-header {
        padding: 1rem 2rem 0rem 2rem;
    }

    .ant-modal-body {
        padding: 1rem 2rem 2rem 2rem;
        ;
    }

    .ant-modal-footer {
        padding: 0rem 0.75rem 1rem 0.75rem;
    }
}

.card-shadow {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 8px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1)
}