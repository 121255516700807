@import '/src/themes/colors/colors.scss';

.login_form_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    .login_header {
        background-color: $AlertBackground;
        width: 100%;
        height: 4rem;
        position: absolute;
        top: 0;
    }

    .validate_div {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10rem;

        .underline_text {
            font-size: 0.8rem;
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
        }

        .check_mail_text {
            font-size: 2rem;
            font-weight: 600;
            font-family: Poppins;
            font-style: normal;
            line-height: normal
        }

        .parah {
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal
        }
    }

}