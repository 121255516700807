.dashboard_note {
  z-index: 900;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .card {
    margin: auto;
    border-radius: 0.5rem;

    &.card_shadow {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 8px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
    }
  }
}
