@import '/src/themes/breakpoints/breakpoints.scss';

.subscribe_form_container {
  padding: 2rem;
  height: 100%;

  @media (max-width: $screen-md-min) {
    padding: 1rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &.leftAlign {
    span {
      width: 100%;
      text-align: left !important;
    }
  }
}

.plan_card {
  text-align: left;

  &:first-child {
    .card_top {
      background-color: #FCF4E9;
    }

    .card_bottom {
      border: 4px solid #FCF4E9;
    }
  }

  &:nth-child(2) {
    .card_top {
      background-color: #FAEBD5;
    }

    .card_bottom {
      border: 4px solid #FAEBD5;
    }
  }

  &:nth-child(3) {
    .card_top {
      background-color: #F8E0BF;
    }

    .card_bottom {
      border: 4px solid #F8E0BF;
    }
  }

  &:nth-child(4) {
    .card_top {
      background-color: #F1C182;
    }

    .card_bottom {
      border: 4px solid #F1C182;
    }
  }

  .card_top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    min-height: 300px;
    background-color: #EDAC5C;

    span {
      width: 100%;
      word-break: keep-all;
    }

    .top_part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem;
      width: 100%;

      &:first-child {
        flex-basis: 33%;
        border-bottom: 1px solid #000;
      }

      &:last-child {
        justify-content: space-between;
        height: 100%;

        .price {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }

  .card_bottom {
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 4px solid #EDAC5C;
    padding: 1rem;

    .feature {
      margin-top: 1px;
      margin-right: 10px;
      color: #42925A;
    }
  }
}

.step0 {
  .notes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    span {
      color: #56575A;
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

.sign_up_container {
  padding: 2rem;

  @media (max-width: $screen-md-min) {
    padding: 0;
  }

  & > div {
    max-width: 768px;

    &.hidden {
      .sign_up_form_item.required label::after {
        visibility: hidden !important;
      }
    }
  }

  &.summary_container > div {
    max-width: 1222px;

    @media (max-width: $screen-xl-min) {
      max-width: 95%;
    }

    @media (max-width: $screen-lg-min) {
      max-width: 98%;
    }
  }
}

.sign_up_form_container {
  border: 1px solid #BCBDC0;
  border-radius: 16px;
  padding: 24px;
  width: 100%;

  @media (max-width: $screen-sm-min) {
    padding: 12px;
  }

  .sign_up_form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;

    &_accordion {
      &_section {
        border: 1px solid #BCBDC0;
        border-radius: 16px;
        padding: 24px;
  
        @media (max-width: $screen-sm-min) {
          padding: 12px;
        }
  
        .sign_up_form_item.hidden,
        .inline_btns.hidden {
          visibility: hidden !important;
          position: absolute;
          opacity: 0;
          z-index: -1;

          &.required label::after,
          .sign_up_form_item.required label::after {
            visibility: hidden !important;
          }
        }
      }

      &_btn {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-weight: 600;

        &:hover {
          color: #000 !important;
        }
      }
    }

    &_section_title {
      display: block;
      color: #001A6E;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
    }

    &_item {
      margin: 0;
      width: 100%;

      &.required label::after {
        visibility: visible !important;
        display: inline !important;
        content: "*";
        color: #FF0000;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
      }

      .checkboxes {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & > span:first-child {
            span {
              border-radius: 4px;
            }
          }
        }

        span {
          color: #56575A;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .dropdowns {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;

        label {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          & > span:first-child {
            span {
              border-radius: 4px;
            }
          }
        }

        @media (max-width: $screen-md-min) {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }

      label {
        height: auto;
        color: #000;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;

        &::before {
          display: none !important;
        }
      }
    }

    &_input {
      margin-top: -8px;
      width: 100%;
      border: 1px solid #BCBDC0;
      border-radius: 8px;
      padding: 5px 8px;
      min-height: 37px;

      & > span {
        margin-inline-end: 10px;
        font-size: 16px;
      }

      input {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      select {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      &.sign_up_form_input_stripe {
        display: flex !important;
        align-items: center;

        & > div {
          width: 100%;
        }
      }
    }

    &_select {
      border: none;
      padding: 0;
      min-height: 37px;

      & > div:first-child {
        border: 1px solid #BCBDC0;
        border-radius: 8px;
        padding: 5px 0 5px 8px;
      }
    }

    &_btn {
      margin-top: 16px;
    }

    .inline_btns {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      width: 100%;

      @media (max-width: $screen-sm-min) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0;
      }

      .inline_btn {
        font-weight: 600;
      }
    }

    .inline_btn {
      color: #2A4C9C;
      cursor: pointer;

      &:hover {
        color: #F7941D;
      }
    }
  }
}


.sign_up_form_note {
  margin-top: 16px;
  width: 100%;
  text-align: center;

  &.small,
  &.small span {
    color: #56575A;
    font-weight: 400;
    font-size: 13px;
    line-height: 15.25px;
  }
}

.review_container {
  border: 1px solid #BCBDC0;
  border-radius: 16px;
  padding: 24px;
  width: 100%;

  @media (max-width: $screen-sm-min) {
    padding: 18px 12px;
  }

  .review {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;

    &_row {
      @media (max-width: $screen-md-min) {
        flex-direction: column-reverse !important;
      }

      &.review_free {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 12px;
        border-bottom: 1px solid #BCBDC0;
        padding-bottom: 24px;
        padding-left: 16px;
        width: 100%;

        @media (max-width: $screen-xl-min) {
          padding-left: 8px;
        }

        @media (max-width: $screen-md-min) {
          padding-left: 0;
        }

        .review_free_newline {
          display: none;

          @media (max-width: $screen-md-min) {
            display: inline-block;
          }
        }
      }
    }

    &_col {
      padding-left: 0 !important;
      padding-right: 0 !important;

      @media (max-width: $screen-xl-min) {
        flex-direction: column !important;
        flex: 1 !important;
      }

      &_col {
        @media (max-width: $screen-xl-min) {
          flex: none !important;
        }

        @media (max-width: $screen-md-min) {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        &:not(:first-of-type) {
          @media (max-width: $screen-xl-min) {
            margin-top: 20px;
          }
        }
      }
    }

    &_section {
      position: relative;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &_sub {
        display: flex;
        flex-direction: column;
        padding: 0 0 16px;
        width: 100%;

        &:not(:last-of-type) {
          margin-bottom: 16px;
          border-bottom: 2px solid #F0F0F0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        &.review_section_sub_edit {
          padding-right: 20px;

          @media (max-width: $screen-md-min) {
            padding-right: 0;
          }
        }
      }

      &_edit {
        // position: absolute;
        // top: 8px;
        // right: 10px;
        margin-left: 10px;
        border: none;
        font-weight: 600;

        &:hover {
          color: #F7941D !important;
        }
      }

      &_title {
        margin-bottom: 10px;

        span {
          color: #000;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          text-align: left;
        }
      }
    }

    &_block {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &_label {
        color: #001A6E;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        text-align: left;
      }

      &_data {
        color: #475467;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }

      .sign_up_form_item {
        margin-bottom: 0 !important;
      }
    }

    &_summary {
      display: flex;
      flex-direction: column;

      &_line {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
      }

      &_label {
        color: #1C4DA1;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        span {
          min-width: 70px;
          color: #475467;
          font-weight: 400;
          text-align: right;
        }
      }

      &_data {
        color: #1C4DA1;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &_total {
      border-top: 2px solid #F0F0F0;
      padding-top: 10px;

      .review_summary_label {
        color: #101828;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
      }

      .review_summary_total {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &_item {
      margin: 0;
      width: 100%;
    }
  }
}

button.primary {
  border: none;
  border-radius: 8px;
  padding: 20px 24px;
  width: 100%;
  background-color: #F7941D;
  color: #000000;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #A4CCFB !important;
    color: #1A2B75 !important;
  }

  span {
    display: block;
    text-align: center;
  }
}

button.secondary {
  border: none;
  border-radius: 8px;
  padding: 15px 20px;
  width: 100%;
  background-color: #fff;
  color: #2A4C9C;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #FAEBD5 !important;
    color: #2A4C9C !important;
  }

  span {
    display: block;
    text-align: center;
  }
}
