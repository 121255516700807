@import '/src/themes/breakpoints/breakpoints.scss';

.auto-responder-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 4rem);
  background: white;
  overflow-y: auto !important;

  @media (max-width: $screen-xl-min) {
    height: calc(100vh - 4rem - 64px);
  }

  .auto-responder-container {
    justify-content: center;
    padding: 2.5rem 2rem;

    @media (max-width: $screen-sm-min) {
      padding: 2rem 1rem;
    }
  }
}
