.error_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100vh;
  
  p {
    text-align: center;
    font-family: Poppins;
  }
}
