@import '/src/themes/breakpoints/breakpoints.scss';

.settings-page {
  width: 100%;
  height: calc(100vh - 4rem);
  background: white;

  @media (max-width: $screen-xl-min) {
    height: calc(100vh - 4rem - 64px);
  }

  .settings-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    border-radius: 0.25rem;
    padding: 2rem;
    text-align: center;

    @media (max-width: $screen-sm-min) {
      padding: 1rem;
      width: 90%;
    }
  }

  .settings-card {
    .ant-card-body {
      width: 100%;
    }
  }

  .settings-space {
    flex-direction: column;

    .ant-space-item {
      width: 100%;
    }
  }

  .card-shadow {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 8px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1)
  }
}
