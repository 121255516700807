.auto-responder-sms-box {
    margin-bottom: 1rem;
    width: 53.12rem !important;

    .sms-box {
        background-color: rgb(250, 232, 208) !important;
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
    }
}

.auto-responder {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0.625rem 0.5rem .75rem;

    .auto-card-1 {
        width: 100%;
        padding: 0.2rem 0rem;

        .ant-card-body {
            padding: 0.5rem 0.75rem;
        }
    }

    .ant-space-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}