@import '/src/themes/breakpoints/breakpoints.scss';

.plan-page {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: calc(100vh - 4rem);
    // background: white;
    text-align: center;
    overflow-y: auto;

    @media (max-width: $screen-xl-min) {
        height: calc(100vh - 4rem - 64px);
    }

    .ant-card {
        border-radius: 10px;
    }

    .ant-card-body {
        padding: 0;
        width: 100%;
    }

    .ant-spin-nested-loading {
        width: 100%;

        .ant-spin-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
    }

    .plan-card-container {
        padding: 50px 50px 80px;
    }

    .plan-card {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 2.5rem 2rem;
        width: 90%;
        background-image: url("../../assets/images/background/plan-bg.png");

        @media (max-width: $screen-md-min) {
            height: auto;
        }

        .ant-space-item {
            width: 100%;
            height: 100%;
        }

        &-header {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .plan-card-top {
                color: #1C4DA1;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
            }

            .plan-card-title {
                color: #000;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
            }

            .plan-card-subtitle {
                color: #56575A;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
            }
        }

        .plan-inner-card-container {
            margin-top: 1rem;
            width: 100%;

            @media (max-width: 840px) {
                flex-direction: column;
                width: 100%;
                height: 100%;
            }

            .plan-inner-card {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 17px;
                border-radius: 20px;
                border: 3px solid #F9A94A;
                padding: 27px;
                min-height: 330px;
                height: 100%;
                background-color: #fff;

                @media (max-width: 1040px) {
                    min-height: 395px;
                }

                @media (max-width: 840px) {
                    width: 100%;
                    min-height: 0;
                }

                &.first-card {
                    justify-content: center;
                    border: transparent;
                    background: radial-gradient(161.29% 161.95% at -12.29% -61.95%, #FFEDD7 0%, #F7941D 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
                }

                &-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 10px;
                }

                &-long {
                    flex-direction: row;
                    justify-content: space-between;
                    min-height: 0;

                    @media (max-width: $screen-md-min) {
                        flex-direction: column;
                        width: 100%;
                        height: 100%;
                    }
                }

                &-payment-method {
                    flex: 0.66;
                    text-align: left;

                    @media (max-width: $screen-md-min) {
                        flex: 1;
                        width: 100%;
                    }
                }

                h4 {
                    margin-bottom: 0;
                    width: 100%;
                    color: #141414;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 27px;
                    text-align: left;
                }

                span.plan-inner-card-text {
                    display: block;
                    width: 100%;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: left;

                    span {
                        font-weight: 700;
                    }
                }
            }

            .plan-inner-card-body {
                height: 18rem !important;
                width: 16rem;
                padding: 2rem;
    
                @media (max-width: $screen-xl-min) {
                    width: 15rem;
                }
    
                @media (max-width: $screen-lg-min) {
                    width: 100%;
                    height: 22rem !important;
                }
    
                @media (max-width: $screen-md-min) {
                    height: auto !important;
                }
            }
        }

        .card-shadow {
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25), 0 2px 8px rgba(0, 0, 0, 0.25), inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
        }
    }

    .plan-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 8.5px;
        background-color: #FEF4E8;

        img {
            height: 25px;
        }
    }

    .plan-payment-method {
        span {
            display: block;
        }
    }

    button.primary {
        border: none;
        border-radius: 8px;
        padding: 20px 24px;
        width: 100%;
        background-color: #F7941D;
        color: #000000;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
    
        &:hover {
        background-color: #A4CCFB !important;
        color: #1A2B75 !important;
        }
    
        span {
        display: block;
        text-align: center;
        }
    }

    button.secondary,
    .button.secondary {
        border: none;
        border-radius: 8px;
        padding: 15px 20px;
        width: 100%;
        background-color: #fff;
        color: #2A4C9C;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
    
        &:hover {
        background-color: #FAEBD5 !important;
        color: #2A4C9C !important;
        }
    
        span {
        display: block;
        text-align: center;
        }
    }
}
