
@import '/src/themes/breakpoints/breakpoints.scss';

.page_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 16px 24px;
  width: 90%;
  min-height: 114px;
  background-color: #fff;

  @media (max-width: 1200px) {
    margin-top: 2rem;
    border-radius: 10px;
  }

  @media (max-width: $screen-md-min) {
    height: auto;
  }

  .breadcrumb_item {
    cursor: pointer;
  }

  .breadcrumb_current {
    color: #000;
  }

  .title {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
    color: #000;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
  }
}
