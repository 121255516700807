@import '/src/themes/colors/colors.scss';

.login_form_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    .login_header {
        background-color: $AlertBackground;
        width: 100%;
        height: 4rem;
        position: absolute;
        top: 0;
    }

    .login_form_wrapper {
        box-shadow: 0rem 0.31rem 0.31rem rgba(138, 137, 137, 0.5);
        padding: 1rem;
        border-radius: 0.12rem;

        .login_form {
            .login_inputs {
                padding: 0.5rem 1rem;
                font-size: 1rem;
                background-color: white;

                .site_form_item_icon {
                    color: gray;
                    margin-right: 1rem;
                    font-size: 1.5rem;
                }
            }
        }
    }

    .validate_div {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10rem;

        .underline_text {
            font-size: 0.8rem;
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;
        }

        .check_mail_text {
            font-size: 2rem;
            font-weight: 600;
            font-family: Poppins;
            font-style: normal;
            line-height: normal
        }

        .parah {
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal
        }
    }

    .underline_text {
        font-size: 0.8rem;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }


}