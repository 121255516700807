@import '/src/themes/colors/colors.scss';

.login_form_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    .login_header {
        background-color: $AlertBackground;
        width: 100%;
        height: 4rem;
        position: absolute;
        top: 0;
    }

    .login_form_wrapper {
        box-shadow: 0rem 0.31rem 0.31rem rgba(138, 137, 137, 0.5);
        padding: 1rem;
        border-radius: 0.12rem;
        width: 28rem;

        .login_form {
            .login_inputs {
                padding: 0.5rem 1rem;
                font-size: 1rem;
                background-color: white;

                .site_form_item_icon {
                    color: gray;
                    margin-right: 1rem;
                    font-size: 1.5rem;
                }
            }
        }
    }

    .underline_text {
        font-size: 0.8rem;
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }

}