@import './themes/colors/colors.scss';
@import '/src/themes/mixins/mixins.scss';
@import '/src/themes/breakpoints/breakpoints.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .dashboard_wrapper {
    height: 100vh;

    .ant-table-thead .ant-table-cell {
      background-color: $secondary-light !important;
      border-bottom: 0.12rem solid $secondary !important;
    }

    .ant-menu-item-selected {
      background-color: $secondary-light !important;
      color: $secondary;
      border-right: 0.3rem solid $secondary;
      border-radius: 0;
    }

    .ant-layout-header {
      .ant-menu-item {
        display: flex;
      }

      .ant-menu-item:hover,
      .ant-menu-item-selected,
      .ant-menu-item-active,
      .ant-menu-submenu-active {
        @media (max-width: $screen-xl-min) {
          border-right: none;

          &::after {
            left: 0 !important;
            right: 0 !important;
            border-bottom-width: 0.3rem !important;
          }
        }
      }
    }

    .ant-layout .ant-layout-sider-children {
      border-inline-end: 0.06rem solid rgba(5, 5, 5, 0.06);
    }

    .ant-menu-light.ant-menu-root.ant-menu-inline {
      border-inline-end: none;
    }

    .ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
      border-inline-end: 0.06rem solid rgba(5, 5, 5, 0.06);
    }
  }
}

/* scrollbar.css */
/* Track */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background-color: $scrollThumb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, $thumbBackground, $scrollThumb, $thumbBackground);
}

/* Track */
::-webkit-scrollbar-track {
  background: $thumbBackground;
}

// classes
.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: end !important;
}

.align-items-start {
  align-items: start !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-left {
  justify-content: left !important;
}

.justify-content-right {
  justify-content: right !important;
}

.text-white {
  color: white !important;
}

.bg-primary {
  background-color: #1C4DA1 !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

// Fontsize 

.font-size-10 {
  font-size: 0.62rem !important;
}

.font-size-11 {
  font-size: 0.68rem !important;
}


.font-size-12 {
  font-size: 0.75rem !important;
}

.font-size-13 {
  font-size: 0.8rem !important;
}

.font-size-14 {
  font-size: 0.875rem !important;
}

.font-size-16 {
  font-size: 1rem !important;
}

.font-size-18 {
  font-size: 1.12rem !important;
}

.font-size-20 {
  font-size: 1.25rem !important;
}

.font-size-22 {
  font-size: 1.37rem !important;
}

.font-size-24 {
  font-size: 1.5rem !important;
}

.font-size-26 {
  font-size: 1.62rem !important;
}

// Font-Weight
.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

// Padding
.p-1 {
  padding: 0.12rem !important;
}

.pt-1 {
  padding-top: 0.12rem !important;
}

.pb-1 {
  padding-bottom: 0.12rem !important;
}

.pl-1 {
  padding-left: 0.12rem !important;
}

.pr-1 {
  padding-right: 0.12rem !important;
}

.p-2 {
  padding: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.25rem !important;
}

.p-3 {
  padding: 0.37rem !important;
}

.pt-3 {
  padding-top: 0.37rem !important;
}

.pb-3 {
  padding-bottom: 0.37rem !important;
}

.pl-3 {
  padding-left: 0.37rem !important;
}

.pr-3 {
  padding-right: 0.37rem !important;
}


.p-4 {
  padding: 0.5rem !important;
}

.pt-4 {
  padding-top: 0.5rem !important;
}

.pb-4 {
  padding-bottom: 0.5rem !important;
}

.pl-4 {
  padding-left: 0.5rem !important;
}

.pr-4 {
  padding-right: 0.5rem !important;
}


.p-5 {
  padding: 0.62rem !important;
}

.pt-5 {
  padding-top: 0.62rem !important;
}


.pb-5 {
  padding-bottom: 0.62rem !important;
}

.pl-5 {
  padding-left: 0.62rem !important;
}

.pr-5 {
  padding-right: 0.62rem !important;
}

.border-radius {
  border-radius: 0.62rem !important;
}

.my-4 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.cursor-pointer {
  cursor: pointer;
}


.w-100 {
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  height: 100%;
  overflow: hidden;
}

.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  text-overflow: ellipsis;
}