@import '/src/themes/breakpoints/breakpoints.scss';

.conversation-page {
    height: calc(100vh - 4rem);

    @media (max-width: $screen-xl-min) {
        height: calc(100vh - 4rem - 64px);
    }

    @media (max-width: $screen-sm-min) {
        height: 100%;
        flex-direction: column !important;
    }

    .ant-layout-sider {
        @media (max-width: $screen-sm-min) {
            max-width: 100% !important;
            width: 100% !important;
            max-height: 30% !important;
            height: 30% !important;
            overflow-y: hidden;
        }
        
        .ant-layout-sider-children > div {
            @media (max-width: $screen-sm-min) {
                max-height: 100% !important;
            }
        }
    }

    .converstion-content {
        display: flex;
        flex-direction: column;
        padding: 1.25rem 1rem 1.25rem 0rem !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;

        @media (max-width: $screen-sm-min) {
            padding: 1.25rem 0 0 0 !important;
            width: 100% !important;
            height: calc(100vh - 4rem - 64px - 250px) !important;
            max-height: 65% !important;
        }

        .ant-spin-nested-loading,
        .ant-spin-nested-loading .ant-spin-container,
        .ant-layout-content {
            @media (max-width: $screen-sm-min) {
                height: 100% !important;
            }
        }

        .subheader-user-info {
            margin-top: 0.5rem;
        }

        .conversation-message {
            display: flex;
            align-items: flex-end;
            height: 100%;
            overflow-y: scroll !important;
            overflow-anchor: auto;

            .ant-spin-nested-loading {
                width: 100%;
                height: 100%;

                .ant-spin-container {
                    width: 100%;
                    height: 100%;
                }
            }

            .message-box {
                height: 100% !important;
                background-color: #F5F5F7 !important;
                overflow-y: auto !important;
                flex-direction: column-reverse;
                display: flex;
            }
        }

        .message-footer {
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            padding: 1rem 0.625rem;
            min-width: 100% !important;
            min-height: 6.25rem !important;
            background-color: #1C4DA1 !important;

            .counter-text {
                display: flex;
                align-items: end;
                justify-content: end;
                color: white !important;
                font-size: 0.875rem;
            }

            button {
                border-radius: 3.15rem !important;
            }
        }

        .add-converstion-btn {
            position: absolute;
            bottom: -3rem;
            right: 0.8rem;
        }
    }
}

.add-convertion-modal {
    .ant-modal-content {
        min-height: 31rem !important;
        width: 47rem !important;
        border-radius: 0.5rem;
        padding: 1rem 2rem 0rem 2rem;
    }

    .ant-modal-close {
        padding-top: .5rem;
        padding-right: 2rem;
        background: transparent;
        color: black;
    }

    .ant-modal-close:hover {
        background: transparent;
    }
}

.conversation-btns,
.msg-btns,
.send-msg-btns {
    justify-content: flex-end;
    gap: 0.5rem;
}

.message {
    border-radius: 0.62rem !important;
    max-width: 55% !important;
    min-width: 180px;
    padding: 0.9rem !important;
}

.message-text {
    margin: 0;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;

    a {
        text-decoration: underline;
    }
}

.message-meta {
    display: flex !important;
    justify-content: space-between !important;
    font-size: 12px;
    color: #666;
    margin: 0;
}

.btn-hover {
    &:hover {
        background: #DBDBDB !important;

        .anticon {
            color: #E94B3D;
        }
    }
}

.send-msg-btn {
    @media (max-width: $screen-lg-min) {
        width: 29px !important;
        height: 29px !important;
    }

    @media (max-width: $screen-md-min) {
        width: 26px !important;
        height: 26px !important;
    }
}
