@import '/src/themes/breakpoints/breakpoints.scss';

.invoice_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 3px solid #FEF4E8;
  border-radius: 10px;

  .invoice_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 8px 15px;
    width: 100%;
    background-color: #FEF4E8;

    @media (max-width: $screen-md-min) {
      flex-direction: column;
      align-items: flex-start;
    }

    &_col {
      display: flex;
      align-items: center;
      padding-left: 0 !important;
    }

    &_title {
      font-size: 1rem;
      font-weight: 600;
    }

    &_status {
      margin-left: 1rem;
      border-radius: 5px;
      padding: 3px 10px;

      &.paid {
        background-color: #D1FAE5;
        color: #10B981;
      }

      &.partial {
        background-color: #FEF3C7;
        color: #F59E0B;
      }

      &.not_paid {
        background-color: #FEE2E2;
        color: #EF4444;
      }
    }
  }

  .invoice_detail {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    width: 100%;

    &_label {
      display: block;
      color: #7f7f7f;
      text-align: left;
    }

    &_data {
      display: block;
      text-align: left;
    }
  }
}
